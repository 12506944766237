<template>
  <footer class="bg-white rounded-lg shadow m-4 dark:bg-gray-800">
    <div
      class="w-full mx-auto max-w-screen-xl p-4 md:pl-28 md:flex md:items-center md:justify-between"
    >
      <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400"
        >© 2023
        <a href="https://flowbite.com/" class="hover:underline"
          >North Simcoe LLP</a
        >. All Rights Reserved.
      </span>
      <ul
        class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0"
      >
        <li>
          <a href="/our-company" class="mr-4 hover:underline md:mr-6">About</a>
        </li>
        <li>
          <a href="/gallery" class="mr-4 hover:underline md:mr-6">Gallery</a>
        </li>
        <li>
          <a href="/gallery" class="mr-4 hover:underline md:mr-6">Services</a>
        </li>
        <li>
          <a href="/contact" class="mr-4 hover:underline md:mr-6">Contact</a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterGlobal",
};
</script>

<style></style>
